import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.scss'
import Cookies from 'js-cookie';

import styled from "styled-components"

import { HomePage, DocumentPage, ItemPage } from 'pages'

const token = Cookies.get('csrf_token');

const Container = styled('div')`
  width: 100%;
  flex-wrap: wrap;
  gap: 32px;
  display: flex;
`

const App = (props) => {
  return token ? (
    <Container>
      <Router>
        <Routes>
          <Route path="/documents/:id" element={<DocumentPage />} />
          <Route path="/items/:id" element={<ItemPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </Container>
  ) : ''
}

export default App
