import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import { Link } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable';

import { getDocument } from 'api/document'
import { getItems } from 'api/item'
import { extractTimemarks } from 'utils/helpers'
import { createMarker, removeMarker } from 'api/marker';

const Container = styled('div')`
  width: 100%;
  
  .Document {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .Header {
    width: 100%;
    background-color: #efefef;
    padding: 16px 16px 0;

    audio {
      width: 100%;
    }

    &-Title {
      position: relative;
      text-align: center;
    }
  
    &-Toggle {
      text-align: center;
      cursor: pointer;
      margin: 0 -16px;
      
      &:hover {
        background-color: #ccc;
      }
    }
  
    &-Actions {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }
  
    &-Back {
      position: absolute;
      left: 0;
      text-decoration: none;
    }

    &-Description {
      padding-bottom: 16px;
      background-color: #efefef;
      height: 500px;
      overflow: auto;
    }
  }

  .Items {
    flex-grow: 1;
    overflow: auto;
  }

  .Item {
    border-bottom: 1px solid #ccc;

    &-Title {
      font-weight: bold;
    }
  }

  .Marker {
    display: flex;
    justify-content: space-between;

    &-Title {
      flex-grow: 1;
    }
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 16px;

    li {
      width: 100%;
    }
    
    a {
      cursor: pointer;
      display: block;

      &:hover {
        background-color: #ccc;
      }
    }
  }
`

const DocumentPage = () => {
  const { id } = useParams();
  const [document, setDocument] = useState()
  const audioRef = useRef()
  const [showDescription, setShowDescription] = useState(false)

  const [itemOptions, setItemOptions] = useState([])
  const [selectedItemOption, setSelectedItemOption] = useState(null)
  const [markerTitle, setMarkerTitle] = useState('')

  useEffect(() => {
    getDocument(id).then((document) => {
      setDocument(document)
    })
    getItems().then((items) => {
      setItemOptions(items.map(({ attributes: { id, title }}) => ({ value: id, label: title })))
    })
  }, [])

  const onAdd = () => {
    if (!selectedItemOption) {
      console.error('selectedItemOption is empty: ', selectedItemOption)
      return
    }
    
    createMarker({
      title: markerTitle,
      start: audioRef?.current?.currentTime,
      document_id: id,
      ...(
        selectedItemOption.__isNew__
          ? {
            item_attributes: {
              title: selectedItemOption.label
            }
          } : {
            item_id: selectedItemOption.value
          }
      )
    }).then((response) => {
      setDocument({
        ...document,
        ...{
          attributes: {
            ...document.attributes,
            ...{
              markers: [
                {
                  ...response.attributes
                },
                ...document.attributes.markers
              ]
            }
          }
        }
      })

      if (selectedItemOption.__isNew__) {
        setItemOptions([
          {
            value: response.attributes.item.id, label: response.attributes.item.title
          },
          ...itemOptions
        ])
      }

      setSelectedItemOption(null)
      setMarkerTitle('')
    }, (response) => {
      console.error(response)
    })
  }

  const moveTo = (time) => {
    const player = audioRef.current

    player.play()
    player.currentTime = time
  }

  const onRemove = (id) => {
    if (window.confirm("Вы ўпэўнены, што жадаеце выдаліць маркер?")) {
      removeMarker(id).then(() => {
        setDocument({
          ...document,
          ...{
            attributes: {
              ...document.attributes,
              ...{
                markers: [
                  ...document.attributes.markers.filter((marker) => (marker.id !== id))
                ]
              }
            }
          }
        })
      }, (response) => {
        console.error(response)
      })
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      onAdd()
    }
  }

  return (
    <Container>
      {
        document && [document].map(({ attributes: { id, title, file_url, description, markers } }) => {
          return (
            <div key={id} className='Document'>
              <section className='Header'>
                <h2 className='Header-Title'>
                  <Link className='Header-Back' to={'/'}>{'<'}</Link>
                  {title}
                </h2>
                <audio controls ref={audioRef}>
                  <source src={file_url} type="audio/mpeg" />
                </audio>
                <div className='Header-Actions'>
                  <CreatableSelect
                    value={selectedItemOption}
                    styles={{ container: (baseStyle) => ({ ...baseStyle, minWidth: '200px' })}}
                    isClearable
                    options={itemOptions}
                    onChange={setSelectedItemOption}
                  />
                  <input value={markerTitle} onChange={(e) => setMarkerTitle(e.target.value)} onKeyPress={handleKeyPress}></input>
                  <button disabled={!selectedItemOption} onClick={onAdd}>Add</button>
                </div>
                <div className="Header-Toggle" onClick={() => setShowDescription(!showDescription)}>
                  { showDescription ? 'Hide' : 'Show' }
                </div>
                {
                  showDescription && (
                    <section className='Header-Description'>
                  
                      <ul>
                        {
                          extractTimemarks(description).map(({ seconds, time, title }, index) => {
                            return (
                              <li key={index}>
                                <a onClick={() => moveTo(seconds)}>
                                  {time + ' - ' + title}
                                </a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </section>
                  )
                }
              </section>
              <section className='Items'>
                <ul>
                  {
                    markers.reduce((items, { item, ...props }) => {
                      const foundItem = items.find(({ id }) => id === item.id)
                      
                      if (foundItem) {
                        foundItem.markers.push(props)
                      } else {
                        items.push({ ...item, markers: [props] })
                      }

                      return items
                    }, []).map(({ id, title, markers }) => {
                      return (
                        <li key={id} className='Item'>
                          <h3 className='Item-Title'>{title}</h3>
                          <ul>
                            {
                              markers.map(({ id, title, start, end }) => {
                                return (
                                  <li key={id} className='Marker'>
                                    <a className="Marker-Title" onClick={() => moveTo(start)}>{title}</a>
                                    <a onClick={() => onRemove(id)}>x</a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </li>
                      )
                    })
                  }
                </ul>
              </section>
            </div>
          )
        })
      }
    </Container>
  )
}

export default DocumentPage
