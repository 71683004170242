const secondsToTime = (seconds) => [
  Math.floor(seconds / 60 / 60),
  Math.floor(seconds / 60),
  seconds % 60
].filter(Number).join(":")

const timeToSeconds = (time) => time
  .split(':')
  .reverse()
  .map((item, index) => Number(item) * 60 ** index)
  .reduce((a, b) => a + b, 0)

const TIME_REGEX = /^\d\d:\d\d:\d\d/

export const extractTimemarks = (text) => text.split("\n").filter((mark) => mark.match(TIME_REGEX)).map(extractStartAndTitle)

const extractStartAndTitle = (mark) => {
  const time = mark.match(TIME_REGEX)[0]

  return {
    time: time,
    seconds: timeToSeconds(time),
    title: mark.match(/(?<=(^\d\d:\d\d:\d\d) ).+/)[0]
  }
}
