import axios from "axios";
import { API_URL } from "constants";
import Cookies from "js-cookie";

export function createMarker(params) {
  const token = Cookies.get('csrf_token');

  console.log("params", params)
  

  return axios.post(`${API_URL}/api/v1/markers.json`, params, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true }})
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);
    })
}

export function removeMarker(id) {
  const token = Cookies.get('csrf_token');

  return axios.delete(`${API_URL}/api/v1/markers/${id}.json`, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true }})
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);
    })
}
  