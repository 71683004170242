import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import { Link } from 'react-router-dom'

import { getItem } from 'api/item'
import { createMarker, removeMarker } from 'api/marker';

const Container = styled('div')`
  width: 100%;
  
  .Item {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .Header {
    width: 100%;
    background-color: #efefef;
    padding: 16px;

    &-Title {
      position: relative;
      text-align: center;
    }
  
    &-Back {
      position: absolute;
      left: 0;
      text-decoration: none;
    }

    audio {
      width: 100%;
    }
  }

  .Documents {
    flex-grow: 1;
    overflow: auto;
  }

  .Document {
    border-bottom: 1px solid #ccc;

    &-Title {
      font-weight: bold;
    }
  }

  .Marker {
    display: flex;
    justify-content: space-between;

    &-Title {
      flex-grow: 1;
    }
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 16px;

    li {
      width: 100%;
    }
    
    a {
      cursor: pointer;
      display: block;

      &:hover {
        background-color: #ccc;
      }
    }
  }
`

const ItemPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState()
  const audiosRef = useRef([])
  const [showDescription, setShowDescription] = useState(false)

  const [itemOptions, setItemOptions] = useState([])
  const [selectedItemOption, setSelectedItemOption] = useState(null)
  const [markerTitle, setMarkerTitle] = useState('')

  useEffect(() => {
    getItem(id).then(setItem)
  }, [])

  const moveTo = (index, time) => {
    const player = audiosRef.current[index]

    player.play()
    player.currentTime = time
  }

  useEffect(() => {
    // audiosRef.current = audiosRef.current.slice(0, 2);
 }, []);

  const onRemove = (id) => {
    if (window.confirm("Вы ўпэўнены, што жадаеце выдаліць маркер?")) {
      removeMarker(id).then(() => {
      //   setDocument({
      //     ...document,
      //     ...{
      //       attributes: {
      //         ...document.attributes,
      //         ...{
      //           markers: [
      //             ...document.attributes.markers.filter((marker) => (marker.id !== id))
      //           ]
      //         }
      //       }
      //     }
      //   })
      }, (response) => {
        console.error(response)
      })
    }
  }

  console.log('audiosRef', audiosRef)
  
  return (
    <Container>
      {
        item && [item].map(({ attributes: { id, title, description, markers } }) => {
          // console.log('markers', markers)
          
          return (
            <div key={id} className='Item'>
              <section className='Header'>
                <h2 className='Header-Title'>
                  <Link className='Header-Back' to={'/'}>{'<'}</Link>
                  <span className='Header-Title'>{title}</span>
                </h2>
              </section>
              <section className='Documents'>
                <ul>
                  {
                    markers.reduce((documents, { document, ...props }) => {
                      const foundItem = documents.find(({ id }) => id === document.id)
                      
                      if (foundItem) {
                        foundItem.markers.push(props)
                      } else {
                        documents.push({ ...document, markers: [props] })
                      }

                      return documents
                    }, []).map(({ id, title, file, markers }, index) => {
                      console.log(id)
                      
                      return (
                        <li key={id} className='Document'>
                          <h3 className='Document-Title'>{title}</h3>
                          <audio controls ref={el => audiosRef.current[index] = el}>
                            <source src={file.url} type="audio/mpeg" />
                          </audio>
                          <ul className='Markers'>
                            {
                              markers.map(({ id, title, start, end }) => {
                                return (
                                  <li key={id} className='Marker'>
                                    <a className="Marker-Title" onClick={() => moveTo(index, start)}>{title}</a>
                                    <a onClick={() => onRemove(id)}>x</a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </li>
                      )
                    })
                  }
                </ul>
              </section>
            </div>
          )
        })
      }
    </Container>
  )
}

export default ItemPage
