import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export function getDocument(id) {
  return axios.get(`${API_URL}/api/v1/documents/${id}.json`, { withCredentials: true })
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return null;
    })
}

export function getDocuments() {
  return axios.get(`${API_URL}/api/v1/documents.json`, { withCredentials: true })
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
