import { useState, useEffect } from 'react'
import styled from "styled-components"
import { getDocuments } from 'api/document'
import { getItems, createItem } from 'api/item'
import { Link } from 'react-router-dom'
import cx from 'classnames'

const Container = styled('div')`
  gap: 48px;
  flex-direction: column;
  display: flex;
  padding: 16px;
  width: 100%;

  .List {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: minmax(110px, auto);

    a {
      text-decoration: none;
    }
  }

  .Document, .Item {
    text-align: center;
    // width: 100px;
    height: 100px;
    border: 3px solid #ccc;
    padding: 8px;
    overflow: hidden;

    &:hover {
      border: 3px solid #33a;
    }
  
    a {
      text-decoration: none;
      color: #000;
    }
    
    &-Image {
      position: absolute;
      padding-top: 50%;
  
      img {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        // object-fit: cover
      }
    }

    &.active {
      border: 3px solid #3a3;
    }
  
    &-Title {
  
    }
  }

  .Document {
    &-Date {
      font-size: 12px;
      font-weight: bold;
    }

    &-Markers, &-Items {
      font-size: 12px;
    }
  }

  .Item {
  }

  .Actions {
    padding: 16px 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
`

const ENTER_KEY = 'Enter'

const HomePage = () => {
  const [documents, setDocuments] = useState([])
  const [items, setItems] = useState([])
  const [itemTitle, setItemTitle] = useState('')
  const [filter, setFilter] = useState('')
  const [hoveredDocument, setHoveredDocument] = useState(null)
  const [hoveredItem, setHoveredItem] = useState(null)

  useEffect(() => {
    getDocuments().then(setDocuments)
    getItems().then(setItems)
  }, [])

  const handleKeyDown = async (e) => {
    if (e.key === ENTER_KEY) {
      onAddItem()
    }
  }

  const onAddItem = () => {
    createItem({ title: itemTitle }).then((item) => {
      setItems([item, ...items])
      setItemTitle('')
    })
  }

  return (
    <Container>
      <section>
        <h2>Запісы</h2>
        <div className='List'>
          {
            documents.map(({ attributes: { id, title, file_url, cover_url, markers_count, item_ids, year }}) => {
              return (
                <Link key={id} to={`/documents/${id}`}>
                  <div
                    className={cx('Document', { active: hoveredItem?.document_ids?.includes(id) })}
                    onMouseEnter={() => setHoveredDocument({ id, item_ids })}
                    onMouseLeave={() => setHoveredDocument(null)}
                  >
                    <div className="Document-Image">
                      <img src={cover_url}/>
                    </div>
                    <div className="Document-Title">
                      {title}
                      <div className='Document-Date'>
                        {year?.replace(/T.+/, "")}
                      </div>
                      <div className='Document-Markers'>
                        Маркераў: {markers_count}
                      </div>
                      <div className='Document-Items'>
                        Аб'ектаў: {item_ids.length}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </section>
      <section>
        <h2>Аб'екты дасьледаваньня</h2>
        <div className='Actions'>
          <input
            placeholder="Новы аб'ект"
            value={itemTitle}
            onChange={(e) => setItemTitle(e.target.value)}
            onKeyDown={handleKeyDown}
          ></input>
          <button disabled={!itemTitle} onClick={onAddItem}>Add</button>
          <input
            placeholder='Фільтр'
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          ></input>
        </div>
        <div className='List'>
          {
            items.map(({ attributes: { id, title, document_ids }}) => {
              if (!title.toLowerCase().includes(filter?.toLowerCase())) {
                return
              }
              
              return (
                <Link key={id} to={`/items/${id}`}>
                  <div
                    className={cx('Item', { active: hoveredDocument?.item_ids?.includes(id) })}
                    onMouseEnter={() => setHoveredItem({ id, document_ids })}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <div className="Item-Image">
                      {/*<img src={cover_url}/>*/}
                    </div>
                    <div className="Item-Title">
                      {title}
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </section>
    </Container>
  )
}

export default HomePage
